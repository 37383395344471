.ts_1 {
  color: #FF4A2E !important;
}
.ts_2 {
  color: #fff !important;
}
.ts_3 {
  color: #2878FF !important;
}
.ts_4 {
  border: 1px solid #2878FF;
}
.framePage {
  padding: 0;
}
.framePage .framePage-title {
  height: 60px;
  background: #2878FF;
  color: #fff;
  align-items: center;
  margin-bottom: 0;
}
.framePage .framePage-title .fl .fl_1 {
  margin-right: 10px;
  font-size: 20px;
}
.framePage .framePage-title .fr .fr_1 {
  cursor: pointer;
}
.framePage .framePage-title .fr .fr_1:hover {
  color: red;
}
.framePage .framePage-body {
  padding: 0 0.675rem;
}
.framePage .framePage-body .operationControl {
  padding: 15px 0.7rem;
  border-bottom: 0;
}
.framePage .framePage-body .operationControl > div {
  font-size: 14px;
}
.framePage .framePage-body .operationControl .o_2 .o_2_2 {
  margin: 0 10px;
}
.framePage .framePage-body .operationControl .o_2 .o_2_3 {
  color: #FF4A2E;
}
.framePage .framePage-body .operationControl .o_3 {
  color: #666666;
  cursor: pointer;
}
.framePage .framePage-body .operationControl .o_3 .o_3_1 span {
  width: 20px !important;
  min-width: 20px;
  padding: 0;
  text-align: center;
  color: #ff892e;
}
.framePage .framePage-body .operationControl .o_3 .o_3_1 i {
  color: #2878FF;
  font-size: 16px;
  margin-right: 4px;
}
.framePage .framePage-body .operationControl .o_3 .o_3_1:hover {
  color: #2878FF;
}
.framePage .framePage-body .operationControl .o_3 .o_3_2 {
  margin-left: 20px;
}
.framePage .framePage-body .operationControl .o_3 .o_3_2 i {
  color: #ff892e;
  font-size: 16px;
  margin-top: 2.5px;
}
.framePage .framePage-body .operationControl .o_3 .o_3_2:hover {
  color: #ff892e;
}
.framePage .framePage-body .framePage-scroll {
  padding: 0;
}
.framePage .framePage-body .framePage-scroll .ovy-a {
  overflow: inherit;
}
.framePage .framePage-body .el-tabs /deep/ .el-tabs__item {
  height: 34px !important;
  line-height: 34px !important;
  font-size: 12px !important;
}
.framePage .framePage-body .el-tabs /deep/ .el-tabs__content {
  min-height: 500px;
}
.framePage .framePage-body .el-tabs .shell {
  height: calc(100vh - 240px);
  overflow-y: auto;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.framePage .framePage-body .el-tabs .shell .shell_1 {
  width: 158px;
  height: 240px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.framePage .framePage-body .el-tabs .shell .shell_1 .img_1 {
  width: 100%;
  height: 100%;
  position: absolute;
}
.framePage .framePage-body .el-tabs .shell .shell_1 .img_2 {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.framePage .framePage-body .el-tabs .shell .shell_1 .img_3 {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.framePage .framePage-body .el-tabs .shell .shell_1 .shell_1_1 {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
.framePage .framePage-body .el-tabs .shell .shell_1 .shell_1_1 .shellf {
  color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 0 4px;
}
.framePage .framePage-body .el-tabs .shell .shell_1 .shell_1_1 .shellf span {
  line-height: 20px;
}
.framePage .framePage-body .el-tabs .el-pagination {
  text-align: right;
}
.proctorsAskedloading {
  line-height: 26px;
}
.proctorsAskedloading .ts {
  color: red;
}
.studentDetailsData .studentDetailsData_t {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
}
.studentDetailsData .studentDetailsData_t .shell_l {
  line-height: 24px;
  margin-right: 20px;
}
.studentDetailsData .studentDetailsData_t .shell_l .p_1 {
  font-size: 20px;
  margin-bottom: 10px;
}
.studentDetailsData .studentDetailsData_t .shell_r .img_1 {
  width: 114px;
  height: 160px;
  border-radius: 4px;
}
.studentDetailsData .studentDetailsData_b {
  margin-top: 20px;
}
.studentDetailsData .studentDetailsData_b .p_1 {
  font-size: 20px;
  margin-bottom: 10px;
}
.studentDetailsData .studentDetailsData_b .shell {
  white-space: nowrap;
  overflow-x: auto;
}
.studentDetailsData .studentDetailsData_b .shell .img_1 {
  width: 94px;
  height: 132px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.studentDetailsData .studentDetailsData_b .shell .img_1.ts_4 {
  border-width: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.studentDetailsData .studentDetailsData_c {
  margin-top: 20px;
}
.studentDetailsData .studentDetailsData_c .p_1 {
  font-size: 20px;
  margin-bottom: 10px;
}
.studentDetailsData .studentDetailsData_c .el-textarea {
  height: 80px;
}
.studentDetailsData .studentDetailsData_c .el-textarea /deep/ .el-textarea__inner {
  height: 80px !important;
}
